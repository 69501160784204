<template>
  <div class="DarkWhite">
    <v-row row no-gutters>
      <v-col cols="12" class="flex flex-items ml-1">
          <div>
            Project Procurement Management Plan (PPMP) Information
          </div>
          <v-divider class="mx-1"></v-divider>
      </v-col>
    </v-row>
    <v-row>
    <v-col cols="12" md="6" lg="3" v-for="(item, index) in items1" :key="item.text">
      <v-card elevation="0" outlined>
        <v-list-item three-line>
          <DoughnutChart class="doughnut-style" :content="item" :ref="'DoughnutChartPPMP' + index+1" />
          <v-list-item-content class="ml-5">
            <v-list-item-subtitle color="#808191" class="text-subtitle-1">{{item.text}}</v-list-item-subtitle>
            <v-list-item-title class="text-h4 mb-1">
              {{parseInt(item.counter)}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" lg="3" v-for="(item, index) in pritems1" :key="item.text">
      <v-card elevation="0" outlined>
        <v-list-item three-line>
          <DoughnutChart class="doughnut-style" :content="item" :ref="'DoughnutChartPPMP2' + index+3" :chartType="index+3" />
          <v-list-item-content class="ml-5">
            <v-list-item-subtitle color="#808191" class="text-subtitle-1">{{item.text}}</v-list-item-subtitle>
            <v-list-item-title class="text-h4 mb-1">
              {{parseInt(item.counter)}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    </v-row>
    <v-row row>
      <v-col cols="12" lg="6">
        <v-card class="card-style" elevation="0" outlined>
        <v-list-item three-line>
          <v-list-item-content class="ml-5">
            <v-list-item-title class="text-h5 my-2">
              Monthly Budget Allocation <small> - <b>{{mba_data.total || 0}}</b> Total Budgets</small>
            </v-list-item-title>
            <v-list-item-subtitle color="#808191" class="text-subtitle-1">
              <BarChart :content="mba_data" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
    </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card class="card-style" elevation="0" outlined>
        <v-list-item three-line>
          <v-list-item-content class="ml-5">
            <v-list-item-title class="text-h5 my-2">
              Monthly Approved PPMPs <small> - <b>{{map_data.total || 0}}</b> Total PPMPs</small>
            </v-list-item-title>
            <v-list-item-subtitle color="#808191" class="text-subtitle-1">
              <BarChart :content="map_data" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
    </v-card>
      </v-col>
    </v-row>

    <!-- <v-row row no-gutters>
      <v-col cols="12">
        <v-list>
          <v-subheader> Budget Utilization Monitoring </v-subheader>
          <v-divider class="mt-n3"></v-divider>
        </v-list>
      </v-col>
    </v-row>
    <div>
      <v-data-table
        :headers="headers"
        :items="budgetUtilizationData"
        :options.sync="options"
        :server-items-length="totalCount"
        :loading="loading"
        :footer-props="footerProps"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              style="max-width: 300px"
              v-model="searchText"
              append-icon="mdi-magnify"
              placeholder="Search for Budget Code"
              color="green darken-2"
              class="mb-2 mr-2"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.PPMPBudgetCode }}</td>
              <td>{{ item.AllocatedBudget }}</td>
              <td>{{ item.UtilizedBudget }}</td>
              <td>{{ item.Savings }}</td>
            </tr>
          </tbody>
        </template>
        <template v-slot:no-data> No Records Yet </template>
      </v-data-table>
    </div> -->
  </div>
</template>

<script>
import BarChart from "../Charts/BarChart.vue";
import DoughnutChart from "../Charts/DoughnutChart.vue";
export default {
  components: {
      BarChart,
      DoughnutChart,
    },
  props: {
    fiscalYear: null,
  },
  data: () => ({
    items1: [
      {
        colors: "green darken-1",
        icon: "mdi-clipboard-check",
        text: "Approved PPMP",
        counter: 0,
        label: ["Approved", "Total"],
        chartType: 1
      },
      {
        colors: "orange darken-1",
        icon: "mdi-clipboard-clock",
        text: "Pending PPMP",
        counter: 0,
        label: ["Pending", "Total"],
        chartType: 2
      },
    ],
    pritems1: [
      {
        colors: "green darken-1",
        icon: "mdi-clipboard",
        text: "Created PPMP",
        counter: 0,
        label: ["Created", "Total"],
        chartType: 3
      },
      {
        colors: "orange darken-1",
        icon: "mdi-clipboard-check-multiple-outline",
        text: "Archived PPMP",
        counter: 0,
        label: ["Archived", "Total"],
        chartType: 4
      },
    ],
    labels_budget: [],
    value_budget: [],
    labels_ppmp: [],
    value_ppmp: [],
    total_budget_count: 0,
    total_ppmp_count: 0,
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    addPPMPDialog: false,
    budgetUtilizationData: [],
    headers: [
      {
        text: "PPMP Budget Code",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Allocated Budget",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Utilized Budget",
        value: "PPMPDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Savings",
        value: "Amount",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      }
    ],
    fiscalyears: [],
    mba_data: [],
    map_data: [],
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.getFiscalYears();
    this.initialize();
    this.eventHub.$on("closePPMPModal", () => {
      this.ppmpItem = {};
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closePPMPModal");
  },
  methods: {
    getLabel(data){
      return this.labels_budget[data.index];
    },
    viewPPMP(item) {
      this.ppmpItem = item;
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      // this.getBudgetUtilization();
      this.budgetChartData();
      this.ppmpChartData();
      this.getPendingPPMPCount();
      this.getApprovedPPMPCount();
      this.getCreatedPPMPCount();
      }
    },
    getBudgetUtilization(){
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append('fiscalYear', this.fiscalYear);
      this.axiosCall("/dashboard/budgetUtilization", "POST", data).then((res) => {
        this.loading = false;
        this.budgetUtilizationData = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getPendingPPMPCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear);
      this.axiosCall("/dashboard/pendingPPMP", "POST", data).then((res) => {
        this.loading = false;
        this.items1[1].counter = res.data.data.result.pendingPPMP || 0.01;
        this.items1[1].total = res.data.data.result.total || 0.01;
      });
    },
    getApprovedPPMPCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear);
      this.axiosCall("/dashboard/approvedPPMP", "POST", data).then((res) => {
        this.loading = false;
        this.items1[0].counter = res.data.data.result.approvedPPMP || 0.01;
        this.items1[0].total = res.data.data.result.total || 0.01;
      });
    },
    getCreatedPPMPCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear);
      this.axiosCall("/dashboard/createdPPMP", "POST", data).then((res) => {
        this.loading = false;
        this.pritems1[0].counter = res.data.data.result[0].createdPPMP || 0.01;
        this.pritems1[0].total = res.data.data.result[0].total || 0.01;
        this.pritems1[1].counter = 0.01;
        this.pritems1[1].total = res.data.data.result[0].total || 0.01;
      });
    },
    budgetChartData(){
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear);
      this.axiosCall("/dashboard/budgetChartData", "POST", data).then(
        (res) => {
          this.loading = false;
          let labels = [];
          let data = [];
          let total = 0;
          res.data.data.result.forEach(item => {
            labels.push(item.monthname + " " + item.year);
          });
          res.data.data.result.forEach(item => {
            data.push(item.totalcount);
            total = total + parseInt(item.totalcount);
          });
          this.mba_data = {
            labels: labels,
            data: data,
            total: total,
            label: 'No. of Budgets Allocated',
          }
        }
      );
    },
    ppmpChartData(){
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear);
      this.axiosCall("/dashboard/ppmpChartData", "POST", data).then(
        (res) => {
          this.loading = false;
          let labels = [];
          let data = [];
          let total = 0;
          res.data.data.result.forEach(item => {
            labels.push(item.monthname + " " + item.year);
          });
          res.data.data.result.forEach(item => {
            data.push(item.totalcount);
            total = total + parseInt(item.totalcount);
          });
          this.map_data = {
            labels: labels,
            data: data,
            total: total,
            label: 'No. of PPMPs Approved',
          }
        }
      );
    },
    getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
  },
};
</script>

<style scoped>
.Offset {
  top: -20px !important;
  position: relative !important;
}
.v-sheet--offset {
  top: -15px;
  position: relative;
}
</style>
